import React from "react";
import SEO from "../components/global/Seo";
import Layout from "../components/layout/Layout";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Masonry from "react-masonry-css";

export default () => {
  const query = useStaticQuery(graphql`
    query {
      bannerImg: file(relativePath: { eq: "ekspresy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bannerImg2: file(relativePath: { eq: "coffee-machine.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sanremo: file(relativePath: { eq: "producenci/sanremo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      jura: file(relativePath: { eq: "producenci/jura.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carimali: file(relativePath: { eq: "producenci/carimali.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      saeco: file(relativePath: { eq: "producenci/saeco.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      klienci: allFile(
        sort: { fields: [base] }
        filter: { relativeDirectory: { eq: "klienci" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout pageTitle="Ekspresy">
      <SEO title="Ekspresy" />
      <div className="row align-items-center section-padding-xs paragraph-content">
        <div className="col-md-6">
          <div className="text-box-padding-xs-right">
            <h2>Dostarczamy ekspresy najwyższej jakości</h2>
            <strong>
              Dobry ekspres gwarantuje doskonały smak kawy. Sprzedajemy
              doradzając, który ekspres będzie dla Ciebie najlepszy.
            </strong>
            <p>
              W naszej ofercie dostępne są profesjonalne ekspresy zarówno
              kolbowe, jak i automatyczne. Zapoznaj się z naszą propozycją
              urządzeń i wybierz coś dla siebie. Sprzedaż, dzierżawa, leasing.
            </p>
            <p>
              Skontaktuj się z nami, nasi konsultanci nie mogą się doczekać
              nowych wyzwań!
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <Img fluid={query.bannerImg.childImageSharp.fluid} alt="Banner" />
        </div>
      </div>
      <div className="row paragraph-content producer-info">
        <div className="col-md-3">
          <h3 className="producer-title">Ekspresy Sanremo</h3>
          <Img
            className="producer-logo"
            fluid={query.sanremo.childImageSharp.fluid}
            alt="Sanremo"
          />
        </div>
        <div className="col-md-8 offset-md-1">
          <p>
            SANREMO jest młodą, dynamicznie rozwijającą się firmą, produkującą
            wysokiej klasy ciśnieniowe ekspresy do kawy, bardzo chętnie
            wykorzystywane w najlepszych lokalach na świecie.
          </p>
          <p>
            Kreatywny zespół projektantów i konstruktorów tworzy nowatorskie
            rozwiązania, które umożliwiają przygotowanie wyśmienitej kawy, w
            sposób przystępny i łatwy dla każdego użytkownika. Wszystkie
            ekspresy poddawane są wnikliwej kontroli i nadzorowi na każdym
            etapie produkcji. Produkty „SANREMO” wykonane są z najwyższej
            jakości materiałów. Ścisły i rygorystyczny proces doboru komponentów
            pozwala na tworzenie urządzeń, które przez długi czas są w stanie
            spełniać warunki profesjonalnego i intensywnego użytkowania.
          </p>
        </div>
      </div>
      <div className="row align-items-center paragraph-content producer-info">
        <div className="col-md-3">
          <h3 className="producer-title">
            W ofercie posiadamy również ekspresy
          </h3>
        </div>
        <div className="col-md-2 offset-md-1">
          <Img fluid={query.jura.childImageSharp.fluid} alt="Jura" />
        </div>
        <div className="col-md-2 offset-md-1">
          <Img fluid={query.carimali.childImageSharp.fluid} alt="Carimali" />
        </div>
        <div className="col-md-2 offset-md-1">
          <Img fluid={query.saeco.childImageSharp.fluid} alt="Saeco" />
        </div>
      </div>
      <div className="row align-items-center justify-content-end section-padding-xs image-text-box paragraph-content">
        <div className="box-image">
          <Img fluid={query.bannerImg2.childImageSharp.fluid} alt="Banner" />
        </div>
        <div className="col-md-8 col-lg-6">
          <div className="box-text">
            <h3>Rekomendują nas między innymi</h3>

            <Masonry
              breakpointCols={4}
              className="masonry-grid mt-4"
              columnClassName="masonry-grid_column"
            >
              {query.klienci.edges.map((edge, i) => {
                return (
                  <div key={i}>
                    <Img
                      key={i}
                      fluid={edge.node.childImageSharp.fluid}
                      alt="Klient logo"
                    />
                  </div>
                );
              })}
            </Masonry>
          </div>
        </div>
      </div>
    </Layout>
  );
};
